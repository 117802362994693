.instagram__widget {
  margin: 0 auto;
  border-radius: 6px;
  box-shadow: 0px 6px 15px -10px rgba(0, 0, 0, 0.75);
  /* background-color: #fff; */
}
.widget__header {
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: none !important;
  box-shadow: inset 0 0 100px rgba(255, 255, 255, 0), 0 1px 0 0 rgba(0, 0, 0, 0.05);
  background: #3897f0;
  border-radius: 6px 6px 0 0;
  text-decoration: none;
  transition: all 0.3s ease;
  color: white;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px;
}
.w-header__avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  vertical-align: middle;
  border-style: none;
}
.w-header__username {
  padding-left: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 450px) {
  .w-header__username {
    line-height: 14px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.w-header__logo {
  margin-left: auto;
  margin-right: 0;
}
.w-header__logo svg {
  fill: #ffffff;
  overflow: hidden;
  vertical-align: middle;
}
.widget__stats {
  width: 100%;
  /* height: 60px; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  background-color: #f7f7f7;
  box-shadow: inset 0 0 100px rgba(255, 255, 255, 0), 0 1px 0 0 rgba(0, 0, 0, 0.05);
}
.stats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.stats__number {
  width: 33.3%;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}
.stats__title {
  color: #999;
  font-weight: 400;
}
.widget__gallery {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  background-color: #fff;
}
@media (max-width: 850px) {
  .widget__gallery {
    grid-template-columns: auto auto auto;
  }
  .widget__gallery .photo__caption p {
    font-size: 0.9em !important;
  }
}
@media (max-width: 600px) {
  .widget__gallery {
    grid-template-columns: auto auto;
  }
  .widget__gallery .photo__caption p {
    font-size: 0.8em !important;
  }
}
@media (max-width: 450px) {
  .widget__gallery {
    grid-template-columns: auto;
  }
  .widget__gallery .photo__caption p {
    font-size: 0.7em !important;
  }
}
@media (max-width: 375px) {
  .widget__gallery .photo__caption p {
    font-size: 1em !important;
  }
}
.photo__link {
  display: block !important;
  text-decoration: none;
  position: relative;
  display: inline-block;
  overflow: hidden;
  max-width: 100%;
  height: auto;
  width: calc(33% - 10px);
  margin: 5px;
}
.photo__image {
  max-width: 100%;
  height: auto;
  -o-object-fit: contain;
  object-fit: contain;
  vertical-align: middle;
  border-style: none;
}
.photo__caption {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff;
  background: #000000;
  text-decoration: none;
  transition: opacity 500ms;
}
.photo__caption p {
  padding: 15px;
  font-size: 0.9em;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  text-align: center;
  position: relative;
}
.photo__caption span {
  color: #eb0000;
}
a:hover .photo__caption {
  opacity: 0.75;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.widget__footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-bottom: 10px;
  border-radius: 0 0 6px 6px;
  background-color: #fff;
}
.footer__button {
  padding: 6px 16px;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  color: #fff;
  background-color: #036ED1;
  border-radius: 3px;
  cursor: pointer;
  text-decoration: none;
}
.footer__button:hover {
  background: #126fc6 !important;
  color: #ffffff;
  text-decoration: none;
}
