.header-4 {
  position: fixed;
  background-color: var(--background-color);
}

.banner {
  width: 100%;
}

.banner-img {
  width: 100%;
}

.banner-img_mobile {
  display: none;
}

@media screen and (max-width: 960px) {
  .header-4__social-icon {
    width: 32px;
    height: 32px;
  }
}

@media screen and (max-width: 600px) {
  .banner-img_pc {
    display: none;
  }

  .banner-img_mobile {
    display: block;
  }
}