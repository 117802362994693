.footer-1 {
  margin-top: auto;
  margin-bottom: 0;
}

.footer-1__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1056px;
  padding: 0 48px;
  z-index: 1000;
}

.footer-1__logo {
  margin-right: 104px;
}

.footer-1__logo > img {
  width: 233px;
}

.footer-1__menu {
  display: flex;
}

.footer-1__menu-button {
  display: flex;
  align-items: center;
  font-size: 17px;
  line-height: 24px;
  font-weight: 600;
  margin: 12px;
  margin-left: 12px;
  text-decoration: none;
  color: #fff;
  transition: all .2s;
}

.footer-1__menu-button:first-child {
  margin-left: 0;
}

@media screen and (max-width: 1056px) {
  .footer-1__content {
    flex-direction: column;
    align-items: center;
  }

  .footer-1__logo {
    margin-right: 0;
  }

  .footer-1__menu {
    margin-top: 44px;
    flex-direction: column;
    align-items: center;
  }

  .footer-1__menu-button {
    margin: 12px 0;
  }

  .footer-1__menu-button:first-child {
    margin-top: 0;
  }

  .footer-1__menu-button:last-child {
    margin-bottom: 0;
  }
}