.reviews-1__header {
  color: #fff;
  margin-bottom: 72px;
}

.reviews-1__carousel-wrapper {
  width: 100%;
  height: 100%;
}

.reviews-1__carousel {
  position: relative;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.reviews-1__carousel-button {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 0;
  /* border: 1px solid #eee; */
  border: none;
  border-radius: 50%;
  background-color: #fff;
}

.reviews-1__carousel-arrow {
  /* fill: var(--primary-color); */
  fill: #000;
  transition: fill 0.2s;
}

.reviews-1__carousel-button:disabled > .reviews-1__carousel-arrow {
  fill: #eee;
}

.reviews-1__carousel-button:focus {
  outline: none;
}

.reviews-1__carousel-slider {
  width: 100%;
  /* padding: 0 29px */
}

.reviews-1__carousel-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 29px;
}

.reviews-1__carousel-photo-wrap {
  width: 170px;
  height: 170px;
  background: #eeeeee;
  border-radius: 50%;
  margin-bottom: 12px;
}

.reviews-1__carousel-photo {
  width: 100%;
  border-radius: 50%;
}

.reviews-1__carousel-name {
  margin: 12px 0;
  text-align: center;
  color: #fff;
}

.reviews-1__carousel-text {
  color: var(--text-color_light);
  margin-top: 12px;
  text-align: center;
  color: #fff;
}

@media screen and (max-width: 1070px) {
  .reviews-1__header {
    margin-bottom: 48px;
  }

  .reviews-1__carousel-button {
    position: absolute;
    top: 67px;
    width: 36px;
    height: 36px;
    z-index: 50;
  }

  .reviews-1__carousel-button_left {
    left: 0;
  }

  .reviews-1__carousel-button_right {
    right: 0;
  }
}
