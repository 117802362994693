.input-wrapper {
  display: flex;
  width: 100%;
  margin: 12px 0;
}

.input-wrapper:first-of-type {
  margin-top: 0;
}

.input-wrapper:last-of-type {
  margin-bottom: 0;
}

.input-wrapper__inside {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input {
  width: 100%;
  height: 48px;
  padding: 13px 0 11px 16px;
  background-color: #fff;
  border: 1px solid var(--box-border-color);
  border-radius: 4px;
  /* opacity: 0.8; */
  transition: all 0.2s;
}

.input_textarea {
  height: 168px;
}

.input::placeholder {
  color: var(--text-color_light);
}

.input:last-of-type {
  margin-bottom: 0;
}

.input:focus {
  outline: none;
  border: 1px solid var(--box-border-color_focus);
  /* color: #fff; */
}

.input:hover {
  border: 1px solid var(--box-border-color__hover);
  background: #f5f5f5;
}

.input_error {
  box-shadow: none;
  /* color: #818181 !important; */
  border: 1px solid var(--error-color) !important;
}

.input__error-wrapper {
  display: flex;
  align-items: center;
}

.input_filled {
  border: 1px solid #FFB800;
}

.input:-moz-ui-invalid {
  box-shadow: none;
  color: var(--error-color);
  border-bottom: 1px solid var(--error-color);
}

.input__error-text {
  color: var(--error-color);
}

@media screen and (max-width: 1070px) {
  .input-wrapper {
    margin: 6px 0;
  }

  .input__error-wrapper {
    align-items: baseline;
  }
}