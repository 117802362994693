.about-us {
}

.about-us__content_column {
  align-items: flex-start !important;
}

.about-us__heading {
  color: var(--accent-color);
  margin-bottom: 36px;
}

.about-us__text-block {
  max-width: 725px;
}

.about-us__text {
  margin-bottom: 10px;
}

.about-us__text > b {
  font-weight: 600;
}

.about-us__text:last-child {
  margin-bottom: 0;
}

.about-us__buttons-block {
  margin-top: 48px;
}