.header {
  position: relative;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}

.header__logo {
  display: flex;
  align-items: center;
  margin-right: 40px;
}

.header__logo > img {
  width: 116px;
}

.header__content {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1056px;
  height: 72px;
  width: 100%;
  padding: 0 48px;
}

.header__button {
  background: none;
  transition: all .2s;
}

.header__button:hover {
  opacity: .7;
}

.header__button-burger {
  margin-left: 28px;
}

@media screen and (max-width: 960px) {
  .header__content {
    justify-content: flex-end;
  }  
}

@media screen and (max-width: 500px) {
  .header__content {
    padding: 0 12px;
  }
}