.menu-f-scr-2 {
  width: 100%;
}

.menu-f-scr-2__header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 1056px;
  width: 100%;
  padding: 0 48px;
  height: 72px;
  background-color: #fff;
}

.menu-f-scr-2__header-content {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.menu-f-scr-2__link {
  margin: 0 12px 0 0;
}

.menu-f-scr-2__button {
  margin: 12px 0;
  color: #fff;
}

.menu-f-scr-2__button-close {
  margin-top: 0;
}

.menu-f-scr-2__button:last-child {
  margin-bottom: 0;
}

.menu-f-scr-2__button:first-child {
  margin-top: 0;
}

.menu-f-scr-2__social {
  display: flex;
  margin-right: 40px;
}

.menu-f-scr-2__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.menu-f-scr-2__bg-wrapper {
  z-index: -1;
}

.menu-f-scr-2__logo {
  margin-bottom: 40px;
}

.menu-f-scr-2__logo > img {
  width: 200px;
}

.menu-f-scr-2__links {
  align-items: center;
}

@media screen and (max-width: 960px) {
  .menu-f-scr-2__social-icon {
    width: 32px;
    height: 32px;
  }
}

@media screen and (max-width: 500px) {
  .menu-f-scr-2__header {
    padding: 0 24px 0 12px;
  }
}