.block {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;
}

.block__content {
  width: 1056px;
  height: 100%;
  padding: 0 48px;
  z-index: 1000;
}

.block__content_column {
  position: relative;
  width: 1056px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 48px;
  z-index: 1000;
}

.block__content_row {
  position: relative;
  width: 1056px;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 48px;
  z-index: 1000;
}

.block__content_wide {
  width: 100%;
}

.block__bg-wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  contain: content;
  z-index: 100
}

.block__bg {
  position: relative;
  object-fit: cover;
  width: 100%;
}

@media screen and (max-width: 1070px) {
  .block {
    padding: 48px 0 60px 0;
  }

  .block__content {
    width: 100%;
  }

  .block__content_column {
    width: 100%;
    /* width: 100vw; */
  }

  .block__content_row {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .block__content {
    width: 100%;
  }

  .block__content_column {
    width: 100%;
    /* width: 100vw; */
    padding: 0 12px;
  }

  .block__content_row {
    padding: 0 12px;
  }
}