.button {
  padding: 12px 32px;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  /* color: #fff; */
  color: var(--text-color_inversed);
  background-color: var(--primary-color);
  border-radius: 3px;
  cursor: pointer;
}

.button:focus {
  outline: none;
}

.button_light {
  color: var(--text-color_inversed);
  padding: 11px 31px;
  /* border: 1px solid #fff; */
  background-color: transparent;
  /* background-color: rgba(99, 99, 99, 0.58); */
}

.button_accent {
  color: var(--text-color);
  background-color: var(--accent-color);
}


@media screen and (max-width: 500px) {
  .button {
    padding: 12px 15px;
  }

  .button_light {
    padding: 11px 14px;
  }
}