.price-t-4__title {
  margin-bottom: 72px;
}

.price-t-4__table-wrapper {
  width: 100%;
  overflow-x: auto;
}

.price-t-4__content {
  align-items: flex-start;
}

.price-t-4__table {
  max-width: 632px;
  width: 100%;
  border-spacing: 0;
}

.price-t-4__t-head {
  height: 48px;
  background-color: var(--accent-color);
  color: var(--text-color);
}

.price-t-4__th {
  text-align: start;
}

.price-t-4__th-1 {
  padding-left: 24px;
  padding-right: 20px;
}

.price-t-4__th-2 {
  
}

.price-t-4__th-3 {
  
}

.price-t-4__row {
  padding: 0;
}

.price-t-4__row:nth-child(odd) {
  background-color: #eeeeee;
}

.price-t-4__cell {
  min-height: 48px;
  padding: 9px 0;
}

.price-t-4__col-first {
  padding-left: 24px;
  padding-right: 20px;
}

.price-t-4__col-mid {
  padding-right: 20px;
  padding-left: 20px;
}

.price-t-4__col-last {
  min-width: max-content;
  padding-right: 24px;
  padding-left: 20px;
}


@media screen and (max-width: 1070px) {
  .price-t-4__title {
    margin-bottom: 48px;
  }

  .price-t-4__col-first {
    padding-left: 12px;
    padding-right: 20px;
  }

  .price-t-4__col-last {
    padding-right: 12px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 500px) {
  .price-t-4__content {
    padding: 0;
  }

  .price-t-4__title {
    margin-left: 12px;
  }
}