.carousel-arrow {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #EEE;
}

.carousel-arrow__svg {
  fill: var(--primary-color);
}

@media screen and (max-width: 1070px) {
  .carousel-arrow {
    width: 40px;
    height: 40px;
    opacity: .7;
  }
}