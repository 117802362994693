@font-face{
  font-family: 'Montserrat';
  src: url(Montserrat/Montserrat-Thin.ttf);
  font-weight: 100;
}

@font-face{
  font-family: 'Montserrat';
  src: url(Montserrat/Montserrat-ThinItalic.ttf);
  font-weight: 100;
  font-style: italic;
}

@font-face{
  font-family: 'Montserrat';
  src: url(Montserrat/Montserrat-ExtraLight.ttf);
  font-weight: 200;
}

@font-face{
  font-family: 'Montserrat';
  src: url(Montserrat/Montserrat-ExtraLightItalic.ttf);
  font-weight: 200;
  font-style: italic;
}

@font-face{
  font-family: 'Montserrat';
  src: url(Montserrat/Montserrat-Light.ttf);
  font-weight: 300;
}

@font-face{
  font-family: 'Montserrat';
  src: url(Montserrat/Montserrat-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
}

@font-face{
  font-family: 'Montserrat';
  src: url(Montserrat/Montserrat-Regular.ttf);
  font-weight: 400;
}

@font-face{
  font-family: 'Montserrat';
  src: url(Montserrat/Montserrat-Italic.ttf);
  font-weight: 400;
  font-style: italic;
}

@font-face{
  font-family: 'Montserrat';
  src: url(Montserrat/Montserrat-Medium.ttf);
  font-weight: 500;
}

@font-face{
  font-family: 'Montserrat';
  src: url(Montserrat/Montserrat-MediumItalic.ttf);
  font-weight: 500;
  font-style: italic;
}

@font-face{
  font-family: 'Montserrat';
  src: url(Montserrat/Montserrat-SemiBold.ttf);
  font-weight: 600;
}

@font-face{
  font-family: 'Montserrat';
  src: url(Montserrat/Montserrat-SemiBoldItalic.ttf);
  font-weight: 600;
  font-style: italic;
}

@font-face{
  font-family: 'Montserrat';
  src: url(Montserrat/Montserrat-Bold.ttf);
  font-weight: 700;
}

@font-face{
  font-family: 'Montserrat';
  src: url(Montserrat/Montserrat-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
}

@font-face{
  font-family: 'Montserrat';
  src: url(Montserrat/Montserrat-ExtraBold.ttf);
  font-weight: 800;
}

@font-face{
  font-family: 'Montserrat';
  src: url(Montserrat/Montserrat-ExtraBoldItalic.ttf);
  font-weight: 800;
  font-style: italic;
}

@font-face{
  font-family: 'Montserrat';
  src: url(Montserrat/Montserrat-Black.ttf);
  font-weight: 900;
}

@font-face{
  font-family: 'Montserrat';
  src: url(Montserrat/Montserrat-BlackItalic.ttf);
  font-weight: 900;
  font-style: italic;
}