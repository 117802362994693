.carousel {
  display: flex;
  /* flex-direction: column; */
}

.carousel__inside {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
}

.carousel__row {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.carousel__slide{
  margin: 10px;
}

.carousel__slide:first-child {
  padding-left: 0;
}

.carousel__slide:last-child {
  padding-right: 0;
}

@media screen and (max-width: 500px) {
  .carousel {
    overflow-x: scroll;
  }

  .carousel__inside {
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: auto;
    margin: 0;
    padding: 0 6px;
  }

  .carousel__slide {
    margin: 6px;
  }

  /* .carousel__inside {
    display: flex;
    flex-direction: row;
    padding: 0 12px;
  }

  .carousel__row {
    padding: 0 6px;
  }

  .carousel__row:first-child {
    padding-left: 0;
  }

  .carousel__row:last-child {
    padding-right: 0;
  }

  .carousel__slide {
    padding: 0 6px;
    width: auto !important;
  } */
}