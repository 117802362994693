.p-page-1 {
}

.p-page-1__to-main {
  display: flex;
  align-items: center;
  margin-bottom: 48px;
  color: var(--text_color);
}

.p-page-1__to-main:visited {
  color: var(--text_color);
}

.p-page-1__to-main-arrow {
  display: flex;
  align-items: center;
  margin-right: 24px;
}

.p-page-1__to-main-text {
  font-size: 19px;
}

.p-page-1__content_column {
  align-items: flex-start;
}

.p-page-1__heading {
  color: var(--accent-color);
  margin-bottom: 36px;
}

.p-page-1__text-block {
  max-width: 725px;
}

.p-page-1__text {
  margin-bottom: 10px;
}

.p-page-1__text > b {
  font-weight: 600;
}

.p-page-1__text:last-child {
  margin-bottom: 0;
}

.p-page-1__buttons-block {
  margin-top: 48px;
}

.p-page-1__gallery {
  width: 100%;
  margin-bottom: 48px;
}

.p-page-1__left-nav {
  position: absolute;
  left: 0;
  padding: 50px 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 4;
}

.p-page-1__right-nav {
  position: absolute;
  right: 0;
  padding: 50px 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 4;
}

.p-page-1__thumbnail {
  border: 2px solid transparent !important;
}

.p-page-1__thumbnail.active,
.p-page-1__thumbnail:hover,
.p-page-1__thumbnail:focus {
  border: 2px solid var(--primary-color) !important;
}


@media screen and (max-width: 1070px) {
  .p-page-1__to-main {
    margin-bottom: 24px;
  }

  .p-page-1__to-main-arrow {
    margin-right: 12px;
  }

  .p-page-1__to-main-arrow > img {
    width: 30px;
    height: 25px;
  }

  .p-page-1__to-main-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  .p-page-1__gallery {
    margin-bottom: 36px;
  }

  .p-page-1__heading {
    margin-bottom: 24px;
  }
}


@media screen and (max-width: 500px) {

}