.card {
  width: 225px;
  min-height: 220px;
  height: 100%;
  padding: 0 28px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #F0F0F0;
  border-radius: 6px;
  box-sizing: border-box;
  transition: all 0.3s;
}

.card:hover {
  background-color: #fff;
  box-shadow: 6px 6px 10px rgba(186, 186, 186, .5);
}

.card__icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 155px;
}

.card__icon {
  width: 100%;
  height: 100%;
}

.card__header {
  font-size: 19px;
  line-height: 21px;
  font-weight: 600;
  margin-bottom: 23px;
  text-align: center;
}

.card__text-wrapper {
  width: 100%;
}

.card__text {
  line-height: 20px;
  font-weight: 300;
  text-align: center;
}