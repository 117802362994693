.features-4 {
  padding-bottom: 45px;
}

.features-4__header {
  margin-bottom: 25px;
  color: var(--accent-color);
}

.features-4__content {
  align-items: flex-start;
  /* padding-right: 33px; */
}

.features-4__grid {
  display: flex;
  flex-wrap: wrap;
  width: 975px;
}

.features-4__cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 140px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 15px;
}

.features-4__cell > img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1070px) {
  .features-4__grid {
    width: calc(100% + 15px);
  }

  .features-4__cell {
    width: 142px;
    height: 110px;
    margin: 0 12px 0
  }
}