.portfolio-6__header {
  margin-bottom: 72px;
}

.portfolio-6__photos {
  display: flex;
  flex-direction: column;
}

.portfolio-6__photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.portfolio-6__photos-line-1 {
  display: flex;
  margin-bottom: 12px;
}

.portfolio-6__photos-line-1-1 {
  width: 632px;
  height: 444px;
  margin-right: 12px;
  border-radius: 6px;
  background-color: #eee;
}

.portfolio-6__photos-line-1-2 {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.portfolio-6__photos-line-1-2-1 {
  width: 304px;
  height: 210px;
  margin-bottom: 12px;
  border-radius: 6px;
  background-color: #eee;
}

.portfolio-6__photos-line-1-2-2 {
  width: 304px;
  height: 210px;
  margin-top: 12px;
  border-radius: 6px;
  background-color: #eee;
}

.portfolio-6__photos-line-2 {
  display: flex;
  margin-top: 12px;
}

.portfolio-6__photos-line-2-1 {
  width: 304px;
  height: 264px;
  margin-right: 12px;
  border-radius: 6px;
  background-color: #eee;
}

.portfolio-6__photos-line-2-2 {
  width: 632px;
  height: 264px;
  margin-left: 12px;
  border-radius: 6px;
  background-color: #eee;
}

@media screen and (max-width: 1070px) {
  .portfolio-6__header {
    margin-bottom: 48px;
  }

  .portfolio-6__photos-line-1 {
    flex-direction: column;
    margin: 0;
  }

  .portfolio-6__photos-line-1-1 {
    width: 100%;
    height: 70vw;
    margin: 0 0 6px 0;
  }

  .portfolio-6__photos-line-1-2 {
    flex-direction: row;
    margin-left: 0;
    margin: 6px 0;
  }

  .portfolio-6__photos-line-1-2-1 {
    width: calc(50% - 6px);
    height: calc(69vw / 2);
    margin: 0 6px 0 0;
  }
  
  .portfolio-6__photos-line-1-2-2 {
    width: calc(50% - 6px);
    height: calc(69vw / 2);
    margin: 0 0 0 6px;
  }

  .portfolio-6__photos-line-2 {
    flex-direction: column;
    margin: 0;
  }

  .portfolio-6__photos-line-2-1 {
    width: 100%;
    height: 87vw;
    margin: 6px 0;
  }
  
  .portfolio-6__photos-line-2-2 {
    width: 100%;
    height: 42vw;
    margin: 6px 0;
  }
}