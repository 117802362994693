.contacts-map-1 {
  padding: 72px 0 80px;
}

.contacts-map-1__content {
  align-items: flex-start;
}

.contacts-map-1__header {
  margin-bottom: 48px;
  color: var(--accent-color);
}

.contacts-map-1__location {
  display: flex;
  margin-bottom: 18px;
}

.contacts-map-1__photo-wrapper {
  width: 304px;
  height: 304px;
  background-color: #eee;
  border-radius: 7px;
  margin-right: 12px;
}

.contacts-map-1__photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.contacts-map-1__map {
  width: 632px;
  height: 304px;
  background-color: #eee;
  border-radius: 7px;
  margin-left: 12px;
}

.contacts-map-1__contacts {
  display: flex;
  margin-top: 18px;
}

.contacts-map-1__info-wrapper-1 {
  display: flex;
  width: 304px;
  margin-right: 12px;
}

.contacts-map-1__info-wrapper-2 {
  display: flex;
  width: 632px;
  margin-left: 12px;
}

.contacts-map-1__info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contacts-map-1__info-text {
  margin-bottom: 12px;
  font-weight: 400;
}

.contacts-map-1__info-text > a {
  text-decoration: none;
  color: var(--primary-color);
}

.contacts-map-1__info-text:last-child {
  margin-bottom: 0;
}

.contacts-map-1__social {
  display: flex;
  align-items: center;
  margin-top: 24px;
  text-decoration: none;
  color: var(--primary-color);
  font-weight: 600;
}

.contacts-map-1__social:first-child {
  margin-trim: 12px;
}

.contacts-map-1__social > img {
  margin-right: 16px;
}

.contacts-map-1__info-header {
  margin-bottom: 12px;
  font-size: 24px;
  line-height: 30px;
  color: var(--accent-color);
}

.contacts-map-1__inst-block {
  max-width: 500px;
  margin-top: 12px;
}

@media screen and (min-width: 860px) {
  .contacts-map-1__inst-block {
    display: none;
  }
}

@media screen and (max-width: 500px) {}


@media screen and (max-width: 1070px) {
  .contacts-map-1__header {
    margin-bottom: 48px;
  }
  
  .contacts-map-1__location {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 500px;
  }

  .contacts-map-1__photo-wrapper {
    width: 100%;
    height: 228px;
    margin: 0 0 6px 0;
  }

  .contacts-map-1__map {
    width: 100%;
    height: 228px;
    margin: 6px 0 0 0;
  }

  .contacts-map-1__contacts {
    flex-direction: column;
    width: 100%;
  }

  .contacts-map-1__social {
    margin-top: 12px;
  }

  .contacts-map-1__info-wrapper-1 {
    width: auto;
    margin: 0 0 12px 0;
  }

  .contacts-map-1__info-wrapper-2 {
    width: auto;
    flex-direction: column;
    margin: 12px 0 0;
  }

  .contacts-map-1__info {
    margin: 12px 0;
  }

  .contacts-map-1__info:first-child {
    margin-top: 0;
  }

  .contacts-map-1__info:last-child {
    margin-bottom: 0;
  }
}
