.intro-8 {
  height: 100%;
  padding: 95px 0;
}

/* alignment: intro-8_align-start для выравнивания контента по левой части экрана */
.intro-8_align-start {
  align-items: flex-start !important;
  text-align: start !important;
}

.intro-8__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.intro-8__heading {
  text-align: center;
  margin-bottom: 12px;
  color: #fff;
}

.intro-8__text-big-body {
  text-align: center;
  color: #fff;
}

.intro-8__buttons-block {
  display: flex;
  margin-top: 36px;
}

.intro-8__button-first {
  margin-right: 12px;
  background-color: #fff;
  color: var(--accent-color);
}

.intro-8__button-second {
  margin-left: 12px;
}

@media screen and (max-width: 1070px) {
  .intro-8 {
    min-height: auto;
    padding: 70px 0;
  }

  .intro-8__heading {
    max-width: 333px;
  }

  .intro-8__content {
    width: 100%;
  }


  .intro-8__text-big-body {
    max-width: 390px;
  }
}

@media screen and (max-width: 500px) {
  .intro-8 {
    min-height: auto;
    padding: 70px 0;
  }

  .intro-8__content {
    width: 100%;
    margin: 0;
  }

  .intro-8__text-big-body {
    max-width: 311px;
  }
}
