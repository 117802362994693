.header-menu {
  display: flex;
  align-items: center;
}

.header-link {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  font-size: 17px;
  line-height: 24px;
  font-weight: 600;
  margin-right: 24px;
  text-decoration: none;
  color: #044D84;
  transition: all .2s;
}

.header-link > a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-link:hover {
  opacity: .7;
}

.header-link:last-child {
  margin-right: 0;
}

.header-link:focus {
  outline: none;
}

@media screen and (max-width: 960px) {
  .header-link {
    margin-right: 12px;
  }
}