.map {
  width: 100%;
  height: 100%;
  border-radius: 7px;
}

.ymaps-2-1-76-inner-panes {
  border-radius: 7px;
}

.map__placemark {
  fill: green;
}