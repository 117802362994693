h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  margin: 0;
}

h1 {
  font-family: var(--font-family);
  font-weight: bold;
  font-size: 48px;
  line-height: 59px;
  color: var(--header-color__h1);
}

h2 {
  font-family: var(--font-family);
  font-size: 48px;
  line-height: 60px;
  color: var(--header-color__h2);
}

h3 {
  font-family: var(--font-family);
  font-size: 45px;
  line-height: 50px;
  color: var(--header-color__h3);
}

h4 {
  font-family: var(--font-family);
  font-size: 36px;
  line-height: 40px;
  color: var(--header-color__h4);
}

h5 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
}

.text_body {
  font-family: var(--font-family);
  font-size: 16px;
  line-height: 24px;
  color: var(--text-color_body);
  font-weight: normal;
}

.text_big-body {
  font-family: var(--font-family);
  font-size: 20px;
  line-height: 30px;
  color: var(--text-color_big-body);
  font-weight: normal;
}

.text_light-body {
  font-family: var(--font-family);
  font-size: 16px;
  line-height: 24px;
  color: var(--text-color_light);
  font-weight: normal;
}



div,
input,
textarea {
  box-sizing: border-box;
  background-clip: padding-box;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 1000px;
  margin: 0;
  background-color: #fff;
  font-family: 'Montserrat';
  font-weight: normal;
}

button {
  border: none;
  cursor: pointer;
  padding: 0;
  background: none;
}

button:focus {
  outline: none;
}

a {
  cursor: pointer;
  text-decoration: none;
}

.app {
  width: 100%;
  height: 100%;
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.page__content {
  width: 100%;
}

.page__content_limited-width {
  /* width: 1000px; */
  padding: 0 20px;
}

@media screen and (max-width: 1070px) {
  body {
    min-width: auto;
  }

  h1 {
    font-size: 28px;
    line-height: 34px;
  }

  h2 {
    font-size: 28px;
    line-height: 34px;
  }

  .text_body {
    font-size: 14px;
    line-height: 20px;
  }

  .text_big-body {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 500px) {
  body {
    min-width: auto;
  }

  .text_big-body {
    font-size: 14px;
    line-height: 21px;
  }
}