.features-3__header {
  /* align-self: center; */
  margin-bottom: 48px;
  margin-left: 10px;
  color: #fff;
}

.features-3__content {
  align-items: unset;
  padding: 0 36px;
}

.features-3__card-header {
  color: #044D84;
}


@media screen and (max-width: 500px) {
  .features-3__header {
    margin-bottom: 36px;
  }

  .features-3__content {
    width: 100%;
    padding: 0;
  }
}