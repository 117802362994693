:root {
  --background-color: #fff;
  --text-color: #333;
  --box-border-color: #DADADA;
  --box-border-color_focus: #333333;
  --box-border-color__hover: #999999;
  --font-family: 'Montserrat';
  --text-color_light: #999999;
  --accent-color: #044D84;
  --text-color_inversed: #fff;
  --primary-color: #036ED1;
  --error-color: #C60000;
}

:root {
  --header-color: var(--text-color);
  --label-color: var(--text-color);
  --text-color_body: var(--text-color);
  --text-color_big-body: var(--text-color);
  --text-color__button: var(--text-color_inversed);
  --text-color__button_transparent: var(--primary-color);
}

:root {
  --header-color__h1: var(--header-color);
  --header-color__h2: var(--header-color);
  --header-color__h3: var(--header-color);
  --header-color__h4: var(--header-color);
  --header-color__h5: var(--header-color);
  --text-color__button: var(--text-color_bold);
  --text-color__checkbox: var(--text-color_bold);
}