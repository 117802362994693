.c-form-1 {
  padding-bottom: 48px;
}

.c-form-1__header {
  margin-bottom: 72px;
  color: #fff;
}

.c-form-1__content {
  align-items: flex-start;
}

.c-form-1__title {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #FFB800;
  margin-bottom: 24px;
  align-self: flex-start;
}

.c-form-1__form-inst-wrapper {
  width: 100%;
  display: flex;
}

.c-form-1__form {
  width: 100%;
  max-width: 468px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.c-form-1__inst-block {
  width: 305px;
  /* height: 384px; */
  margin-left: 50px;
  /* background-color: #fff; */
}

.c-form-1__input-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.c-form-1__input {
  width: 100%;
}

.c-form-1__error-text {
  margin-left: 12px;
}

.c-form-1__c-number {
  font-size: 16px;
  font-weight: 700;
  margin-left: 16px;
}

.c-form-1__bottom {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
}

.c-form-1__button {
  margin-right: 18px;
  margin-bottom: 0;
  background-color: #fff;
  color: var(--accent-color);
}

.c-form-1__result-wrapper {
  margin-left: 18px;
  max-width: 370px;
}

.c-form-1__success {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}

.c-form-1__error {
  font-size: 20px;
  font-weight: 600;
  color: var(--error-color);
}

@media screen and (max-width: 1070px) {
  .c-form-1__header {
    margin-bottom: 48px;
  }

  .c-form-1__input-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .c-form-1__error-text {
    margin-left: 0;
    padding-top: 8px;
  }
}

@media screen and (max-width: 860px) {
  .c-form-1__inst-block {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .c-form-1__bottom {
    flex-direction: column;
  }

  .c-form-1__button {
    margin: 0 0 12px 0;
  }

  .c-form-1__result-wrapper {
    margin: 12px 0;
  }
}