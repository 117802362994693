.menu {
  position: fixed;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 10000;
}

.menu__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu__links {
  display: flex;
  flex-direction: column;
}

.menu__link {
  display: flex;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  text-decoration: none;
  color: #000000;
  transition: all .2s;
  margin: 12px 0;
}

.menu__link:first-child {
  margin-top: 0;
}

.menu__link:last-child {
  margin-bottom: 0;
}

.menu__link:hover {
  opacity: .7;
}

.menu__link:focus {
  outline: none;
}

.menu__footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.menu__action-button,
.menu__contacts,
.menu__socials {
  margin: 12px 0;
}

.menu__action-button:first-child,
.menu__contacts:first-child,
.menu__socials:first-child {
  margin-top: 0;
}

.menu__action-button:last-child,
.menu__contacts:last-child,
.menu__socials:last-child {
  margin-bottom: 0;
}


.menu__contact {
  font-size: 14px;
  font-weight: bold;
  color: var(--text-color);
  margin-bottom: 3px;
}

.menu__contact:last-of-type {
  margin-bottom: 0;
}

.menu__socials {
  display: flex;
}

.menu__social {
  margin: 0 2px;
  transition: all 0.2s;
  cursor: pointer;
}

.menu__social:hover {
  opacity: 0.7;
}

.menu__social:first-child {
  margin-left: 0;
}

.menu__social:last-child {
  margin-right: 0;
}



